import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getCheatingsAPI,
  getCheatingsDPVideoAPI,
  getCheatingsVideoAPI,
  getFullCheatingsAPI,
  getVideoCheatingsAPI,
} from "./api";

export const getCheatings = createAsyncThunk(
  "report/getLmsList",
  async (attemp_id: string) => {
    const res = await getCheatingsAPI(attemp_id);
    return res;
  }
);

export const getFullCheatings = createAsyncThunk(
  "report/getLmsList",
  async (attemp_id: string) => {
    const res = await getFullCheatingsAPI(attemp_id);
    return res;
  }
);

export const getCheatingsVideo = createAsyncThunk(
  "report/getCheatingsVideo",
  async (attemp_id: string) => {
    const res = await getCheatingsVideoAPI(attemp_id);
    return res;
  }
);

export const getCheatingsDPVideo = createAsyncThunk(
  "report/getCheatingsDPVideo",
  async (attemp_id: string) => {
    const res = await getCheatingsDPVideoAPI(attemp_id);
    const data = res.filter((el) => el.reportType === "@PROCTORING/DOUBLE");
    if (data.length > 0) return data[0];
    return data[0];
  }
);

export const getVideoCheatings = createAsyncThunk(
  "report/getVideoCheatings",
  async ({ attemp_id, videoId }: { attemp_id: string; videoId: number }) => {
    const res = await getVideoCheatingsAPI(attemp_id, videoId);
    return res;
  }
);
