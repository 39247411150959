import { createSlice } from "@reduxjs/toolkit";
import {
  Attempt,
  Course,
  mapDataWithStatus,
  Quiz,
  ResultStats,
  Status,
  Univer,
  UserInfo,
} from "types";

import type { RootState } from "store";
import { status } from "utils";

import {
  getCourseList,
  getCourseQuizzes,
  getCourseUsers,
  getLmsList,
  getProctoredQuizzes,
  getQuizResults,
  postCheatingStatus,
  getQuizStats,
} from "./thunk";

const initialState: State = {
  courses: {
    data: [],
    status: { ...status },
  },
  quizzes: {
    data: [],
    status: { ...status },
  },
  students: {
    data: [],
    status: { ...status },
  },
  univers: {
    data: [],
    status: { ...status },
  },
  results: {
    data: [],
    status: { ...status },
    stats: null,
  },
  postStatus: {
    ...status,
  },
};

export const lmsSlice = createSlice({
  name: "lms",
  initialState,
  reducers: {},
  extraReducers: (buildCase) =>
    buildCase
      .addCase(getCourseList.pending, (state) => ({
        ...state,
        courses: {
          ...state.courses,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getCourseList.rejected, (state) => ({
        ...state,
        courses: {
          ...state.courses,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getCourseList.fulfilled, (state, { payload }) => ({
        ...state,
        courses: {
          data: payload.result,
          status: { ...status, success: true },
        },
      }))
      .addCase(getCourseUsers.pending, (state) => ({
        ...state,
        students: {
          ...state.students,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getCourseUsers.rejected, (state) => ({
        ...state,
        students: {
          ...state.students,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getCourseUsers.fulfilled, (state, { payload }) => ({
        ...state,
        students: {
          data: payload,
          status: { ...status, success: true },
        },
      }))
      .addCase(getProctoredQuizzes.pending, (state) => ({
        ...state,
        quizzes: {
          ...state.quizzes,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getProctoredQuizzes.rejected, (state) => ({
        ...state,
        quizzes: {
          ...state.quizzes,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getProctoredQuizzes.fulfilled, (state, { payload }) => ({
        ...state,
        quizzes: {
          data: payload.quizzes,
          status: { ...status, success: true },
        },
      }))
      .addCase(getLmsList.pending, (state) => ({
        ...state,
        univers: {
          ...state.univers,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getLmsList.rejected, (state) => ({
        ...state,
        univers: {
          ...state.univers,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getLmsList.fulfilled, (state, { payload }) => ({
        ...state,
        univers: {
          data: payload.result,
          status: { ...status, success: true },
        },
      }))
      .addCase(getCourseQuizzes.pending, (state) => ({
        ...state,
        quizzes: {
          ...state.quizzes,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getCourseQuizzes.rejected, (state) => ({
        ...state,
        quizzes: {
          ...state.quizzes,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getCourseQuizzes.fulfilled, (state, { payload }) => ({
        ...state,
        quizzes: {
          data: payload.quizzes,
          status: { ...status, success: true },
        },
      }))
      .addCase(getQuizResults.pending, (state) => ({
        ...state,
        results: {
          ...state.results,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getQuizResults.rejected, (state) => ({
        ...state,
        results: {
          ...state.results,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getQuizResults.fulfilled, (state, { payload }) => ({
        ...state,
        results: {
          data: payload,
          status: { ...status, success: true },
          stats: null,
        },
      }))
      .addCase(getQuizStats.pending, (state) => ({
        ...state,
        results: {
          ...state.results,
        },
      }))
      .addCase(getQuizStats.rejected, (state) => ({
        ...state,
        results: {
          ...state.results,
        },
      }))
      .addCase(getQuizStats.fulfilled, (state, { payload }) => ({
        ...state,
        results: {
          ...state.results,
          stats: payload,
        },
      }))
      .addCase(postCheatingStatus.pending, (state) => ({
        ...state,
        postStatus: {
          ...status,
          loading: true,
        },
      }))
      .addCase(postCheatingStatus.rejected, (state) => ({
        ...state,
        postStatus: {
          ...status,
          failure: true,
        },
      }))
      .addCase(postCheatingStatus.fulfilled, (state) => ({
        ...state,
        postStatus: {
          ...status,
          success: true,
        },
      })),
});

export const selectCourses = (state: RootState) => state.lms.courses;
export const selectStudents = (state: RootState) => state.lms.students;
export const selectQuizzes = (state: RootState) => state.lms.quizzes;
export const selectUnivers = (state: RootState) => state.lms.univers;
export const selectResults = (state: RootState) => state.lms.results;
export const selectPostStatus = (state: RootState) => state.lms.postStatus;

type State = {
  courses: mapDataWithStatus<Course[]>;
  quizzes: mapDataWithStatus<Quiz[]>;
  students: mapDataWithStatus<UserInfo[]>;
  univers: mapDataWithStatus<Univer[]>;
  results: mapDataWithStatus<Attempt[]> & { stats: ResultStats | null };
  postStatus: Status;
};

export default lmsSlice.reducer;
