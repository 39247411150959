import axios, { AxiosRequestConfig } from "axios";
import { refreshTokenAPI } from "store/users/api";

const axiosRequest = async <T>(config: AxiosRequestConfig): Promise<T> => {
  try {
    const res = await axios.request<T>({ ...config });
    const setCookie = res.headers["set-cookie"];
    axios.defaults.headers.common.cookie = setCookie;

    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(`axios error: ${error.name}`, error.toJSON());
    }
    throw error;
  }
};

const axiosResInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        !error.response ||
        (error.response && error.response.status !== 401)
      ) {
        return Promise.reject(error);
      }

      axios.interceptors.response.eject(interceptor);

      return refreshTokenAPI()
        .then(() => {
          return axios(error.response.config);
        })
        .catch((error1) => {
          window.location.replace("/oauth2");
          return Promise.reject(error1);
        })
        .finally(axiosResInterceptor);
    }
  );
};

export { axiosRequest, axiosResInterceptor };
