import { useEffect, useState } from "react";

import PeerJS from "peerjs";
import io, { Socket } from "socket.io-client";

import {
  LiveProctoringInitStudentCR,
  LiveProctoringProctorInitSR,
  StudentPeer,
} from "../types";

const SOCKET_IO_URL =
  process.env.REACT_APP_SOCKET_IO_URL || "https://proctoring.aeroexam.org";
const LIVE_PROCTORING_INIT_ROOM = "LIVE_PROCTORING_INIT";

export const ICE_SERVERS = [{ url: "stun:stun1.l.google.com:19302" }];

export const useProctorSocket = (cheatingCodes: string[]) => {
  const [peer, setPeer] = useState<PeerJS>();
  const [socket, setSocket] = useState<Socket>();
  const [students, setStudents] = useState<Record<string, StudentPeer>>({});
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      const peerClient = new PeerJS({
        config: {
          iceServers: ICE_SERVERS,
        },
      });

      /**
       * on peer connect, we send peerId to proctor
       */
      peerClient.on("open", () => {
        console.debug("[LIVE_PROCTORING] connected succesfully");
        setPeer(peerClient);
      });

      return () => {
        peerClient?.disconnect();
      };
    }
  }, [socket]);

  useEffect(() => {
    const socketClient = io(SOCKET_IO_URL, {
      reconnection: true,
      transports: ["websocket", "polling"],
      withCredentials: true,
    });
    /**
     * msg.socketId - student's socketId
     * msg.peerId - student's peerId
     * msg.user - user info
     */
    socketClient.on(
      LIVE_PROCTORING_INIT_ROOM,
      (msg: LiveProctoringInitStudentCR) => {
        // TODO: save user info
        console.log("[LIVE_PROCTORING] student connected", msg);
        setStudents({
          ...students,
          [msg.peerId]: {
            ...msg.user,
            stream: null,
          },
        });
      }
    );

    socketClient.on("connect", () => {
      console.log("[LIVE_PROCTORING] connected succesfully");
      setSocket(socketClient);
    });

    return () => {
      socketClient?.off(LIVE_PROCTORING_INIT_ROOM);
      socketClient?.off("connect");
      socketClient?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (peer && socket) {
      peer.on("call", (call) => {
        call.answer();
        call.on("stream", (stream) => {
          setStudents({
            ...students,
            [call.peer]: {
              ...students[call.peer],
              stream,
            },
          });
        });
      });

      // TODO: connect to students that are not in the *students* list every second
      cheatingCodes.forEach((cheatingCode) => {
        const message: LiveProctoringProctorInitSR = {
          cheatingCode,
          peerId: peer.id,
        };
        socket.emit(LIVE_PROCTORING_INIT_ROOM, message);
      });
    }
  }, [peer]);

  const onPeerLeave = (peerId: string) => {
    const newStudents = { ...students };
    delete newStudents[peerId];
    setStudents(newStudents);
  };

  return { peers: students, isConnected: !!socket, onPeerLeave };
};
