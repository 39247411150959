import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Link, TableCell, TableRow, Typography } from "@material-ui/core";

import { Layout } from "components/Layout";
import { CommonTable } from "components/Table";
import { Search } from "components/Search";

import { selectCourses } from "store/lms/slice";
import { getCourseList } from "store/lms/thunk";
import { routes } from "utils/routes";

const { useState, useEffect } = React;

const CoursesPage: React.FC = () => {
  const [searchData, setSearchData] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const cells = [
    t("coursePage.title"),
    t("coursePage.students"),
    t("coursePage.exams"),
  ];

  const courses = useSelector(selectCourses);

  useEffect(() => {
    if (!courses.data.length) dispatch(getCourseList());
  }, []);

  return (
    <Layout title={t("sidebar.courses")}>
      <h1>{t("coursePage.title")}</h1>
      {courses.status.loading && <h4>{t("search")}</h4>}
      {courses.status.failure && <h4>{t("no_courses")}</h4>}
      {courses.data.length > 5 && (
        <Search searchData={searchData} setSearchData={setSearchData} />
      )}
      {!!courses.data.length && (
        <CommonTable cells={cells}>
          {courses.data
            .filter(
              (item) =>
                item &&
                item.fullname?.toUpperCase().indexOf(searchData.toUpperCase()) >
                  -1
            )
            .map((el) => (
              <TableRow key={el.shortname}>
                <TableCell component="th" scope="row">
                  {el.fullname}
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    <Link href={`${routes.students.url}/${el.id}`}>
                      {t("check")}
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    <Link href={`${routes.quizzes.url}/${el.id}`}>
                      {t("check")}
                    </Link>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </CommonTable>
      )}
      {!courses.data.length && !courses.status.loading && t("no_results")}
    </Layout>
  );
};

export { CoursesPage };
