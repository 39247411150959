import React, { MutableRefObject, useEffect, useRef } from "react";

import { Card, CardContent } from "@material-ui/core";
import styles from "./style.module.css";

type Props = {
  mediaStream: MediaStream;
  studentName: string;
  onMediaStop: () => void;
};
const SECOND_MS = 1000;

export function StudentStream({
  mediaStream,
  studentName,
  onMediaStop,
}: Props) {
  const videoRef =
    useRef<HTMLVideoElement>() as MutableRefObject<HTMLVideoElement>;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.srcObject = mediaStream;
      video.onloadedmetadata = () => {
        video.play();
      };
      return () => {
        video.pause();
      };
    }
  }, [videoRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      // this active is always true, this will never get executed
      // probably bug
      if (!mediaStream.active) {
        onMediaStop();
      }
    }, SECOND_MS);

    return () => clearInterval(interval); //
  }, [mediaStream]);

  return (
    <Card>
      <CardContent>
        <video className={styles.StyledVideo} ref={videoRef} autoPlay muted />
      </CardContent>
      <div className={styles.StyledCardFooter}>{studentName}</div>
    </Card>
  );
}
