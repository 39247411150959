import _ from "lodash";

const API_PREFIX = "";

export const routes = _.mapValues(
  {
    home: { url: "/", allow: ["PROCTOR", "ADMIN"] },
    courses: { url: "/courses", allow: ["PROCTOR", "ADMIN"] },
    students: { url: "/students", allow: ["PROCTOR", "ADMIN"] },
    quizzes: { url: "/quizzes", allow: ["PROCTOR", "ADMIN"] },
    dashboard: { url: "/dashboard", allow: ["PROCTOR", "ADMIN"] },
    auth: { url: "/oauth2", allow: ["PROCTOR", "ADMIN", "STUDENT"] },
    groupResults: { url: "/groupResults", allow: ["PROCTOR", "ADMIN"] },
    report: { url: "/report", allow: ["PROCTOR", "ADMIN"] },
    makeAdmin: { url: "/make-admin", allow: ["PROCTOR", "ADMIN", "STUDENT"] },
    live: { url: "/live", allow: [""] },
  },
  (route) => ({
    ...route,
    url: API_PREFIX + route.url,
  })
);

console.log(routes);
