import { createAsyncThunk } from "@reduxjs/toolkit";

import { approveImageRequestAPI, rejectImageRequestAPI } from "./api";

export const approveImageRequest = createAsyncThunk(
  "common/approveImage",
  async (id: number) => {
    const res = await approveImageRequestAPI(id);
    return res;
  }
);

export const rejectImageRequest = createAsyncThunk(
  "common/rejectImageRequest",
  async (id: number) => {
    const res = await rejectImageRequestAPI(id);
    return res;
  }
);
