import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";

import { Status } from "types";
import { status } from "utils";

import { approveImageRequest, rejectImageRequest } from "./thunk";

const initialState: State = {
  theme: null,
  imageRequest: { ...status },
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<"dark" | "light">) {
      // eslint-disable-next-line no-param-reassign
      state.theme = action.payload;
    },
  },
  extraReducers: (buildCase) =>
    buildCase
      .addCase(approveImageRequest.pending, (state) => ({
        ...state,
        imageRequest: { ...status, loading: true },
      }))
      .addCase(approveImageRequest.rejected, (state) => ({
        ...state,
        imageRequest: { ...status, failure: true },
      }))
      .addCase(approveImageRequest.fulfilled, (state) => ({
        ...state,
        imageRequest: { ...status, success: true },
      }))
      .addCase(rejectImageRequest.pending, (state) => ({
        ...state,
        imageRequest: { ...status, loading: true },
      }))
      .addCase(rejectImageRequest.rejected, (state) => ({
        ...state,
        imageRequest: { ...status, failure: true },
      }))
      .addCase(rejectImageRequest.fulfilled, (state) => ({
        ...state,
        imageRequest: { ...status, success: true },
      })),
});

export const selectTheme = (state: RootState) => state.common.theme;
export const selectImageRequest = (state: RootState) =>
  state.common.imageRequest;

export const { setTheme } = commonSlice.actions;

type State = {
  theme: "dark" | "light" | null;
  imageRequest: Status;
};

export default commonSlice.reducer;
