import * as React from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactVideo from "react-player";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Container, TableCell, TableRow } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import { Layout } from "components/Layout";
import { CommonTable } from "components/Table";
import { Slider } from "components/Swiper";

import {
  selectCheatings,
  selectCheatingVideo,
  selectDPCheatingVideo,
  selectVideoCheatings,
} from "store/report/slice";
import {
  getCheatings,
  getCheatingsVideo,
  getCheatingsDPVideo,
  getVideoCheatings,
} from "store/report/thunk";
import {
  convertToDuration,
  getCheatingTypeTitle,
  timeStampToDate,
} from "utils";

const useStyles = makeStyles(() =>
  createStyles({
    videoTopWrapper: {
      "@media print": {
        display: "none",
      },
    },
    videoWrapper: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 100,
    },
    player: {
      maxWidth: 600,
      marginRight: 100,
    },
    topWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media print": {
        display: "none",
      },
    },
  })
);

const { useEffect, useRef } = React;

const ReportPage: React.FC = () => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams<{ id?: string }>();
  const classes = useStyles();

  const cheatings = useSelector(selectCheatings);
  const video = useSelector(selectCheatingVideo);
  const dpVideo = useSelector(selectDPCheatingVideo);
  const videoCheatings = useSelector(selectVideoCheatings);

  const cells = [
    t("reportPage.violation"),
    t("reportPage.start"),
    t("reportPage.end"),
    t("examsPage.time"),
  ];

  const videoCells = [
    t("reportPage.start"),
    t("reportPage.end"),
    t("reportPage.violation"),
    t("reportPage.moment"),
  ];

  const handleSeek = (time: number) => {
    // @ts-ignore
    videoRef.current.seekTo(time);
  };

  const handleDownload = () => {
    window.print();
  };

  useEffect(() => {
    if (params.id && !cheatings.data.length) dispatch(getCheatings(params.id));
    if (params.id && !video.data) dispatch(getCheatingsVideo(params.id));
    if (params.id && !dpVideo.data) dispatch(getCheatingsDPVideo(params.id));
  }, []);

  useEffect(() => {
    if (params.id && video.data)
      dispatch(
        getVideoCheatings({ attemp_id: params.id, videoId: video.data.id })
      );
  }, [video.data]);

  return (
    <Layout title={t("individualReport")}>
      <div className={classes.topWrapper}>
        <h2>{t("reportPage.shortReport")}</h2>
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={handleDownload}
        >
          {t("resultPage.downloadReport")}
        </Button>
      </div>
      {cheatings.status.loading && <h4>{t("search")}</h4>}
      {cheatings.status.failure && <h4>{t("no_results")}</h4>}
      {!!cheatings.data.length && (
        <CommonTable cells={cells}>
          {cheatings.data.map((item) => (
            <TableRow key={item.id}>
              <TableCell component="th" scope="row">
                {getCheatingTypeTitle(item.cheatingType)}
              </TableCell>
              <TableCell align="right">
                {timeStampToDate(item.startTime, "HH:mm") || t("timeNot")}
              </TableCell>
              <TableCell align="right">
                {timeStampToDate(item.endTime, "HH:mm") || t("timeNot")}
              </TableCell>
              <TableCell align="right">{t("timeNot")}</TableCell>
            </TableRow>
          ))}
        </CommonTable>
      )}
      {!!cheatings.data.length && (
        <>
          <h2>{t("reportPage.photoReport")}</h2>
          <Container maxWidth="lg">
            <Slider items={cheatings.data} />
          </Container>
        </>
      )}
      {video.data && (
        <div className={classes.videoTopWrapper}>
          <h2>{t("reportPage.videoReport")}</h2>
          <div className={classes.videoWrapper}>
            <ReactVideo
              className={classes.player}
              url={video.data.link}
              ref={videoRef}
              width={1000}
              height={400}
              controls
            />
            {!!videoCheatings.data.length && (
              <CommonTable cells={videoCells} align="left">
                {videoCheatings.data.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align="left">
                      {convertToDuration(item.startTime)}
                    </TableCell>
                    <TableCell align="left">
                      {convertToDuration(item.endTime)}
                    </TableCell>
                    <TableCell align="left">
                      {getCheatingTypeTitle(item.cheatingType)}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        onClick={() => handleSeek(item.startTime)}
                        color="primary"
                      >
                        {t("reportPage.select")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </CommonTable>
            )}
          </div>
        </div>
      )}

      {dpVideo.data && (
        <div className={classes.videoTopWrapper}>
          <h2>{t("reportPage.dpVideoReport")}</h2>
          {dpVideo.data && (
            <ReactVideo
              className={classes.player}
              url={dpVideo.data.link}
              width={1000}
              height={400}
              controls
            />
          )}
        </div>
      )}
    </Layout>
  );
};

export { ReportPage };
