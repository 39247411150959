import * as React from "react";
import i18n, { changeLanguage } from "i18n";

import { Badge, Menu, MenuItem } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TranslateIcon from "@material-ui/icons/Translate";

const { useState } = React;

const LanguageComponent: React.FC<{ styles?: any }> = ({ styles }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLang = (lang: "en" | "kz" | "ru") => {
    changeLanguage(lang);
    handleClose();
  };

  const getLang = () => {
    switch (i18n.language) {
      case "en":
        return "English";
      case "kz":
        return "Қазақша";
      default:
        return "Русский";
    }
  };

  return (
    <div>
      <IconButton
        aria-label="show 4 new mails"
        style={{
          fontSize: 18,
          fontWeight: "bold",
          ...styles,
        }}
        color="inherit"
        onClick={handleClick}
      >
        <Badge color="secondary" style={{ marginRight: 10 }}>
          <TranslateIcon />
        </Badge>
        {getLang()}
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLang("en")}>English</MenuItem>
        <MenuItem onClick={() => handleLang("ru")}>Русский</MenuItem>
        <MenuItem onClick={() => handleLang("kz")}>Қазақша</MenuItem>
      </Menu>
    </div>
  );
};

export { LanguageComponent };
