import * as React from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  Link,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import GetAppIcon from "@material-ui/icons/GetApp";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Layout } from "components/Layout";
import { CommonTable } from "components/Table";
import { Search } from "components/Search";

import { selectResults, selectPostStatus } from "store/lms/slice";
import {
  getQuizResults,
  getQuizStats,
  postCheatingStatus,
} from "store/lms/thunk";

import {
  getAttemptStatusTitle,
  getDecisionColor,
  getDecisionTitle,
  getTrust,
  UTCToDate,
} from "utils";
import { Attempt, AttemptStatus } from "types";
import { routes } from "utils/routes";

const useStyles = makeStyles(() =>
  createStyles({
    honestyWrapper: {
      maxWidth: "40%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    searchWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 30,
      "@media print": {
        display: "none",
      },
    },
  })
);

const { useState, useEffect } = React;

const GroupResultsPage: React.FC = () => {
  const [searchData, setSearchData] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentAttempt, setCurrentAttempt] = useState<Attempt | null>(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams<{ id?: string }>();

  const classes = useStyles();

  const cells = [
    t("resultPage.student"),
    t("resultPage.status.title"),
    t("resultPage.start"),
    t("resultPage.end"),
    t("resultPage.honesty"),
    t("resultPage.report"),
    t("resultPage.decision.title"),
  ];

  const statsCells = [
    t("studentsPage.number"),
    `${t("resultPage.status.title")} "${t("resultPage.status.not_started")}"`,
    `${t("resultPage.status.title")} "${t("resultPage.status.started")}"`,
    `${t("resultPage.status.title")} "${t("resultPage.status.finished")}"`,
  ];

  const results = useSelector(selectResults);
  const postStatus = useSelector(selectPostStatus);

  const handleDialogOpen = (el: Attempt) => {
    setCurrentAttempt(el);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setCurrentAttempt(null);
    setOpenDialog(false);
  };

  const handleDownload = () => {
    window.print();
  };

  const handleSetStatus = (status: "cheated" | "not_cheated") => {
    dispatch(
      postCheatingStatus({
        status,
        cheatingCode: currentAttempt?.cheatingCode || "",
      })
    );
  };

  const getAttemptReportLink = (status: AttemptStatus, id: number) => {
    switch (status) {
      case "STARTED":
        return <p>{t("resultPage.status.started")}</p>;
      case "FINISHED":
        return <Link href={`${routes.report.url}/${id}`}>{t("check")}</Link>;
      default:
        return <p>{t("resultPage.status.not_started")}</p>;
    }
  };

  useEffect(() => {
    if (postStatus.success) {
      if (params.id) dispatch(getQuizResults(+params.id));
      handleDialogClose();
    } else if (postStatus.failure) {
      handleDialogClose();
    }
  }, [postStatus]);

  useEffect(() => {
    if (params.id && !results.data.length) dispatch(getQuizResults(+params.id));
    if (params.id && !results.stats) dispatch(getQuizStats(+params.id));
  }, []);

  return (
    <Layout title={t("examsPage.results")}>
      <h1>{t("examsPage.results")}</h1>
      {results.status.loading && <h4>{t("search")}</h4>}
      {results.status.failure && <h4>{t("no_quizzes")}</h4>}
      {results.stats && <h2>{t("resultPage.stats")}</h2>}
      {results.stats && (
        <CommonTable cells={statsCells} align="left">
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              {results.stats.count}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              {results.stats.not_started}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              {results.stats.in_progress}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              {results.stats.finished}
            </TableCell>
          </TableRow>
        </CommonTable>
      )}
      <Divider style={{ margin: "50px 0" }} />
      {!!results.data.length && (
        <div className={classes.searchWrapper}>
          <Search
            searchData={searchData}
            setSearchData={setSearchData}
            withoutBtm
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={handleDownload}
          >
            {t("resultPage.downloadReport")}
          </Button>
        </div>
      )}
      {!!results.data.length && (
        <CommonTable cells={cells} align="left">
          {results.data
            .filter(
              (item) =>
                item &&
                item.firstName
                  ?.toUpperCase()
                  .indexOf(searchData.toUpperCase()) > -1
            )
            .map((el) => (
              <TableRow key={el.id}>
                <TableCell component="th" scope="row">
                  {el.firstName} {el.lastName}
                </TableCell>
                <TableCell align="left">
                  {getAttemptStatusTitle(el.status)}
                </TableCell>
                <TableCell align="left">
                  {(el.startedAt && UTCToDate(el.startedAt)) || t("timeNot")}
                </TableCell>
                <TableCell align="left">
                  {(el.finishedAt && UTCToDate(el.finishedAt)) || t("timeNot")}
                </TableCell>
                <TableCell align="left">
                  <div className={classes.honestyWrapper}>
                    <FiberManualRecordIcon
                      fontSize="small"
                      style={{
                        color: getTrust(el.score).color,
                        transform: "scale(0.6)",
                      }}
                    />
                    <p>{getTrust(el.score).honesty}</p>
                  </div>
                </TableCell>
                <TableCell align="left">
                  <Typography>
                    {getAttemptReportLink(el.status, el.id)}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    color={getDecisionColor(el.reportStatus)}
                    onClick={() => handleDialogOpen(el)}
                  >
                    {getDecisionTitle(el.reportStatus)}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </CommonTable>
      )}
      {!results.data.length && !results.status.loading && t("no_results")}

      {/* Decision dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {t("disclaimer_cheated")}
        </DialogTitle>
        <DialogActions style={{ margin: 20 }}>
          <Button
            onClick={() => handleSetStatus("cheated")}
            variant="contained"
            color="secondary"
          >
            {t("yes")}
          </Button>
          <Button
            onClick={() => handleSetStatus("not_cheated")}
            variant="contained"
            color="primary"
            autoFocus
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export { GroupResultsPage };
