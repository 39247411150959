export type Course = {
  fullname: string;
  id: number;
  shortname: string;
};

export type Quiz = {
  courseid: number;
  coursename: string;
  id: number;
  name: string;
  timeclose: number;
  timeduration: number;
  timeopen: number;
};

export type Status = {
  success: boolean;
  failure: boolean;
  loading: boolean;
};

export type DeviceStatus = "FAIL" | "SUCCESS" | "NOT_CHECKED";

export type AttemptStatus = "STARTED" | "FINISHED" | "SCHEDULED";

export type AttemptProcessStatus =
  | "IN_PROGRESS"
  | "FINISHED"
  | "FAILED"
  | "AWAITING";

export type DecisionStatus = "NOT_CHEATED" | "CHEATED" | "NOT_SET";

export type User = {
  ImageRequests: UserImageRequest[];
  createdAt: string;
  deviceStatus: {
    AUDIO: { status: DeviceStatus };
    SCREEN: { status: DeviceStatus };
    WEBCAM: { status: DeviceStatus };
  };
  encoding_512: string;
  first_name: string;
  id: number;
  image: string;
  lang: string;
  last_name: string;
  organization_id: number;
  status: string;
  updatedAt: string;
  user_id: number;
  username: string;
};

export type UserImageRequest = {
  createdAt: string;
  id: number;
  image: string;
  organizationCode: string;
  reason: null;
  resolvedById: null;
  status: string;
  updatedAt: string;
  updatedImage: string;
  userId: number;
};

export type Univer = {
  clientId: string;
  code: string;
  createdAt: string;
  fullName: string;
  image: string;
  organizationType: string;
  updatedAt: string;
  url: string;
};

export type Attempt = {
  cheatingCode: string;
  createdAt: string;
  duration: number;
  finishedAt: string;
  firstName: string;
  lastName: string;
  id: number;
  identification: null;
  organizationCode: string;
  reportStatus: DecisionStatus;
  processes: {
    COUNT_SCORE: {
      name: string;
      rule: null;
      status: AttemptProcessStatus;
      parentProcess: null;
    };
  };
  rules: [];
  score: number;
  sent: false;
  startedAt: string;
  status: AttemptStatus;
  updatedAt: string;
  userId: number;
  video360: null;
};

export type ResultStats = {
  average: number;
  cheated: number;
  count: number;
  finished: number;
  in_progress: number;
  max_count: number;
  min_count: number;
  not_started: number;
  not_suspicious: number;
  suspicious: number;
};

export enum CheatingType {
  no_student = "@FACE_REC/NO_STUDENT",
  not_recognized = "@FACE_REC/NOT_RECOGNIZED",
  too_many_people = "@FACE_REC/TOO_MANY_PEOPLE",
  look_away = "@FACE_REC/LOOK_AWAY",
  no_encodings = "@FACE_REC/NO_ENCODINGS",
  browser_focus = "@SCREEN/BROWSER_FOCUS",
  audio = "@AUDIO",
  copy = "@CLIPBOARD/COPY",
  paste = "@CLIPBOARD/PASTE",
  mobile_detected = "@OBJECT_DETECTION/MOBILE",
  offline = "@INTERNET_CONNECTION/OFFLINE",
  webcam_access_removed = "@DEVICE_ACCESS/WEBCAM",
  microphone_access_removed = "@DEVICE_ACCESS/MICROPHONE",
  screen_access_removed = "@DEVICE_ACCESS/SCREEN",
  capturing_tab = "@TABS/FORBIDDEN_TAB",
}

export type Cheating = {
  cheated: boolean;
  cheatingType: CheatingType;
  content: string;
  createdAt: string;
  endTime: number;
  id: number;
  image: string;
  reportType: string;
  startTime: number;
  updatedAt: string;
};

export type Video = {
  createdAt: string;
  duration: number;
  id: number;
  isProcessed: true;
  link: string;
  reportType: string;
  updatedAt: string;
};

export type UserInfo = {
  createdAt: string;
  deviceStatus: {
    AUDIO: { status: DeviceStatus; error?: string };
    SCREEN: { status: DeviceStatus; error?: string };
    WEBCAM: { status: DeviceStatus; error?: string };
  };
  id: number;
  image: string;
  lang: string;
  status: string;
  updatedAt: string;
  email?: string;
  firstName: string;
  imageRequests: UserImageRequest[];
  lastName: string;
  organization: { code: string; url: string; organizationType: string };
  organizationCode: string;
  organizationUserId: string;
};

export interface mapDataWithStatus<T> {
  data: T;
  status: Status;
}

/**
 * this is the message that student sends in response to proctor's request
 */
export interface LiveProctoringInitStudentSR {
  socketId: string;
  user: StudentInfo;
  peerId: string;
}

/**
 * this is the message that proctor receives after server adds student's socketId
 */
export interface LiveProctoringInitStudentCR
  extends LiveProctoringInitStudentSR {
  socketId: string;
}

/**
 * this is the message that proctor sends to student to connect to him
 */
export interface LiveProctoringProctorInitSR {
  cheatingCode: string;
  peerId: string;
}

/**
 * this is the message that student receives after server adds proctor's socketId
 */
export interface LiveProctoringProctorInitCR {
  socketId: string;
  peerId: string;
}

/**
 * student's info, comes with _LiveProctoringInitStudentCR_
 */
export interface StudentInfo {
  name: string;
}

/**
 * student peer
 */
export interface StudentPeer extends StudentInfo {
  stream: null | MediaStream;
}

export interface StudentPeerWithStream extends StudentInfo {
  peerId: string;
  stream: MediaStream;
}
