import * as React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import { selectTheme } from "store/common/slice";

import { CoursesPage } from "pages/Courses";
import { DashboardPage } from "pages/Dashboard";
import { QuizzesPage } from "pages/Quizzes";
import { StudentsPage } from "pages/Students";
import { AuthPage } from "pages/Auth";
import { NotFoundPage } from "pages/404";
import { GroupResultsPage } from "pages/GroupResult";
import { ReportPage } from "pages/Report";
import { MakeAdminPage } from "pages/MakeAdmin";
import { LiveComponent } from "pages/Live";

import { isAuthenticated } from "utils";
import { axiosResInterceptor } from "utils/axios.config";
import { routes } from "./utils/routes";

axiosResInterceptor();

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  name,
  ...rest
}) => {
  const check = isAuthenticated(name);

  return (
    <Route
      {...rest}
      render={(props) =>
        check.allow ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname:
                check.status === "STUDENT"
                  ? routes.makeAdmin.url
                  : routes.auth.url,
            }}
          />
        )
      }
    />
  );
};

const App: React.FC = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const currentTheme = localStorage.getItem("theme");
  const currentThemeFromStore = useSelector(selectTheme);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // @ts-ignore
          type:
            currentThemeFromStore ||
            currentTheme ||
            (prefersDarkMode ? "dark" : "light"),
        },
      }),
    [currentThemeFromStore, currentTheme, prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path={routes.auth.url} component={AuthPage} />
          <PrivateRoute
            exact
            path={[routes.home.url, routes.courses.url]}
            name="home"
            component={CoursesPage}
          />
          <PrivateRoute
            exact
            path={routes.quizzes.url}
            name="quizzes"
            component={QuizzesPage}
          />
          <PrivateRoute
            exact
            path={`${routes.quizzes.url}/:id`}
            name="quizzes"
            component={QuizzesPage}
          />
          <PrivateRoute
            exact
            path={`${routes.groupResults.url}/:id`}
            name="groupResults"
            component={GroupResultsPage}
          />
          <PrivateRoute
            exact
            path={routes.students.url}
            name="students"
            component={StudentsPage}
          />
          <PrivateRoute
            exact
            path={`${routes.students.url}/:id`}
            name="students"
            component={StudentsPage}
          />
          <PrivateRoute
            exact
            path={`${routes.report.url}/:id`}
            name="report"
            component={ReportPage}
          />
          <PrivateRoute
            exact
            path={routes.dashboard.url}
            name="dashboard"
            component={DashboardPage}
          />
          <Route exact path={routes.live.url} component={LiveComponent} />
          <Route exact path={routes.makeAdmin.url} component={MakeAdminPage} />
          <Route exact path="*" component={NotFoundPage} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  name: string;
  [x: string]: any;
}

export { App };
