import React, { useMemo } from "react";
import { useQuery } from "hooks/useQuery";
import { useProctorSocket } from "hooks/useProctorSocket";
import { StudentPeerWithStream } from "types";
import _ from "lodash";
import { StudentStream } from "components/StudentStream";

const LiveComponent: React.FC = () => {
  const query = useQuery();
  const cheatings = query.getAll("cheatingCode");
  console.log(cheatings);
  const { peers, onPeerLeave } = useProctorSocket(cheatings);

  const peersWithStreams = useMemo(
    () =>
      _.entries(peers)
        .map(([peerId, peer]) => ({ ...peer, peerId }))
        .filter(({ stream }) => stream !== null) as StudentPeerWithStream[],
    [peers]
  );

  return (
    <div className="LiveComponent">
      {peersWithStreams.map((peer) => (
        <StudentStream
          key={peer.stream.id}
          onMediaStop={() => onPeerLeave(peer.peerId)}
          studentName={peer.name}
          mediaStream={peer.stream}
        />
      ))}
    </div>
  );
};

export { LiveComponent };
