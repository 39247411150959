import * as React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Container, CssBaseline, makeStyles } from "@material-ui/core";

import { selectUnivers } from "store/lms/slice";
import { getLmsList } from "store/lms/thunk";
import { getUserInfo, oauthAuth } from "store/users/thunk";

import { Univer } from "types";
import { routes } from "utils/routes";
import { LanguageComponent } from "components/Language";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    backgroundColor: "#f1f1f1",
  },
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(3),
    borderRadius: 5,
    border: "0.5px solid #f2f2f2",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
  },
  title: {
    textAlign: "center",
    marginBottom: "50px",
  },
  list: {
    display: "grid",
    listStyle: "none",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr 1fr",
    padding: 0,
    margin: 0,
    gap: "30px 30px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr",
      gap: "0px 0px",
    },
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  listItemImgWrapper: {
    borderRadius: "50%",
    padding: 50,
    border: "4px solid #CBCBD0",
    "&:hover": {
      cursor: "pointer",
      border: "4px solid #0976F4",
    },
  },
  listItemImg: {
    width: 80,
    height: 80,
  },
  listItemTitle: {
    textAlign: "center",
    fontSize: 18,
  },
  topBar: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
  },
}));

const { useEffect } = React;

const AuthPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const univers = useSelector(selectUnivers);

  const login = async () => {
    try {
      const params = new URLSearchParams(history.location.search);
      const organizationName = params.get("name");
      const code = params.get("code");
      if (organizationName && code) {
        await dispatch(oauthAuth({ organizationName, code }));
        await dispatch(getUserInfo());

        window.close();
        setTimeout(() => {
          history.push(routes.courses.url);
        }, 500);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUrl = (lms: Univer) => {
    if (lms.organizationType === "MOODLE") {
      return `${lms.url}/local/oauth/login.php?client_id=${lms.clientId}&response_type=code`;
    }
    return lms.url;
  };

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    if (params.get("code") && params.get("name")) {
      login();
    } else {
      dispatch(getLmsList());
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <CssBaseline />
      <Container component="main">
        <div className={classes.paper}>
          <div className={classes.topBar}>
            <h1 className={classes.title}>{t("chooseUni")}</h1>
            <LanguageComponent
              styles={{
                position: "absolute",
                right: 0,
                top: 15,
              }}
            />
          </div>
          {univers.status.loading && <h4>{t("search")}</h4>}
          {univers.status.failure && <h4>{t("no_results")}</h4>}
          {!!univers.data.length && (
            <ul className={classes.list}>
              {univers.data.map((univer) => (
                <li className={classes.listItem} key={univer.code}>
                  <a
                    href={getUrl(univer)}
                    className={classes.listItemImgWrapper}
                  >
                    <img
                      className={classes.listItemImg}
                      src={
                        univer.image ||
                        "https://thumbs.dreamstime.com/b/university-vector-icon-symbol-creative-sign-education-icons-collection-filled-flat-computer-mobile-illustration-logo-155797331.jpg"
                      }
                      alt="img"
                    />
                  </a>
                  <p className={classes.listItemTitle}>{univer.fullName}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Container>
    </div>
  );
};

export { AuthPage };
