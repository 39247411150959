import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { Link, TableCell, TableRow, Typography } from "@material-ui/core";

import { Layout } from "components/Layout";
import { CommonTable } from "components/Table";
import { Search } from "components/Search";

import { selectQuizzes } from "store/lms/slice";
import { getProctoredQuizzes, getCourseQuizzes } from "store/lms/thunk";
import { timeStampToDate } from "utils";
import { routes } from "utils/routes";

const { useState, useEffect } = React;

const QuizzesPage: React.FC = () => {
  const [searchData, setSearchData] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams<{ id?: string }>();

  const cells = [
    t("examsPage.name"),
    t("examsPage.start"),
    t("examsPage.end"),
    t("examsPage.time"),
    t("examsPage.results"),
  ];

  const quizzes = useSelector(selectQuizzes);

  useEffect(() => {
    if (params.id) dispatch(getCourseQuizzes(+params.id));
    else if (!quizzes.data.length) dispatch(getProctoredQuizzes());
  }, []);

  return (
    <Layout title={t("sidebar.exams")}>
      <h1>{params.id ? quizzes.data[0]?.coursename : t("sidebar.exams")}</h1>
      {quizzes.status.loading && <h4>{t("search")}</h4>}
      {quizzes.status.failure && <h4>{t("no_quizzes")}</h4>}
      {quizzes.data.length > 5 && (
        <Search searchData={searchData} setSearchData={setSearchData} />
      )}
      {!!quizzes.data.length && (
        <CommonTable cells={cells}>
          {quizzes.data
            .filter(
              (item) =>
                item &&
                item.name?.toUpperCase().indexOf(searchData.toUpperCase()) > -1
            )
            .map((el) => (
              <TableRow key={el.id}>
                <TableCell component="th" scope="row">
                  {el.name}
                </TableCell>
                <TableCell align="right">
                  {timeStampToDate(el.timeopen) || t("timeNot")}
                </TableCell>
                <TableCell align="right">
                  {timeStampToDate(el.timeclose) || t("timeNot")}
                </TableCell>
                <TableCell align="right">
                  {timeStampToDate(el.timeduration) || t("timeNot")}
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    <Link href={`${routes.groupResults.url}/${el.id}`}>
                      {t("check")}
                    </Link>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </CommonTable>
      )}
      {!quizzes.data.length && !quizzes.status.loading && t("no_results")}
    </Layout>
  );
};

export { QuizzesPage };
