import moment from "moment";
import i18n from "i18n";

import {
  AttemptStatus,
  CheatingType,
  DecisionStatus,
  DeviceStatus,
  Status,
} from "types";
import { routes } from "./routes";

export const status: Status = {
  success: false,
  failure: false,
  loading: false,
};

export const timeStampToDate = (timestamp: number, format?: string): string =>
  moment.unix(timestamp).format(format || "MM/DD/YYYY HH:mm");

export const UTCToDate = (date: string, format?: string): string =>
  moment(date).format(format || "MM/DD/YYYY HH:mm");

export const getDeviceStatusColor = (deviceStatus: DeviceStatus) => {
  let color: string;

  if (deviceStatus === "SUCCESS") color = "#1DC39A";
  else if (deviceStatus === "FAIL") color = "#E34A30";
  else color = "#979797";

  return color;
};

export const getDeviceStatusTooltip = (deviceStatus: DeviceStatus) => {
  let title: string;

  if (deviceStatus === "SUCCESS") title = i18n.t("studentsPage.turnOn");
  else if (deviceStatus === "FAIL") title = i18n.t("studentsPage.turnOff");
  else title = i18n.t("studentsPage.notChecked");

  return title;
};

export const getAttemptStatusTitle = (attemptStatus: AttemptStatus) => {
  let title: string;

  if (attemptStatus === "STARTED") title = i18n.t("resultPage.status.started");
  else if (attemptStatus === "FINISHED")
    title = i18n.t("resultPage.status.finished");
  else title = i18n.t("resultPage.status.not_started");

  return title;
};

export const getTrustColor = (trust: number) => {
  if (trust > 90) {
    return "#00FF00";
  }
  if (trust > 65) {
    return "#99ff00";
  }
  if (trust > 35) {
    return "orange";
  }
  if (trust === -1) {
    return "gray";
  }
  return "red";
};

export const getTrust = (honesty?: number) => {
  let honestyVal = 0;
  if (honesty) {
    honestyVal = honesty;
  }
  let trust = 0;
  if (!honesty && honesty !== 0) trust = -1;
  else {
    honestyVal = Math.min(100, honestyVal);
    trust = ((100 - honestyVal) / 100) * 100;
  }
  const color = getTrustColor(trust);
  return {
    honesty: trust === -1 ? "n/a" : `${Math.round(trust * 10) / 10}%`,
    color,
  };
};

export const getCheatingTypeTitle = (cheatingType: CheatingType) => {
  switch (cheatingType) {
    case CheatingType.look_away:
      return i18n.t("reportPage.lookAway");
    case CheatingType.no_student:
      return i18n.t("reportPage.noFace");
    case CheatingType.browser_focus:
      return i18n.t("reportPage.browserNotUsed");
    case CheatingType.too_many_people:
      return i18n.t("reportPage.twoPeople");
    case CheatingType.not_recognized:
      return i18n.t("reportPage.unauthorized");
    case CheatingType.copy:
      return i18n.t("reportPage.copyText");
    case CheatingType.paste:
      return i18n.t("reportPage.pasteText");
    case CheatingType.microphone_access_removed:
      return i18n.t("reportPage.noMicro");
    case CheatingType.webcam_access_removed:
      return i18n.t("reportPage.noWebcam");
    case CheatingType.screen_access_removed:
      return i18n.t("reportPage.noDisplay");
    case CheatingType.capturing_tab:
      return i18n.t("reportPage.forbiddenTab");
    case CheatingType.audio:
      return i18n.t("reportPage.speech");
    default:
      return "";
  }
};

export const convertToDuration = (secondsAmount: number): string => {
  const time = moment.duration(secondsAmount, "second");

  return `${time.hours()} ${i18n.t("hours")} ${time.minutes()} ${i18n.t(
    "minutes"
  )} ${time.seconds()} ${i18n.t("seconds")}`;
};

export const getDecisionTitle = (decision?: DecisionStatus) => {
  switch (decision) {
    case "CHEATED":
      return i18n.t("resultPage.decision.cheated");
    case "NOT_CHEATED":
      return i18n.t("resultPage.decision.not_cheated");
    default:
      return i18n.t("resultPage.decision.noDecision");
  }
};

export const getDecisionColor = (decision?: DecisionStatus) => {
  switch (decision) {
    case "CHEATED":
      return "secondary";
    case "NOT_CHEATED":
      return "primary";
    default:
      return "default";
  }
};

export const isAuthenticated = (name: string) => {
  const user = localStorage.getItem("user");

  if (user) {
    const parsed = JSON.parse(user);
    // @ts-ignore
    const allow = routes[name].allow.includes(parsed.status);

    return {
      allow,
      status: parsed.status,
    };
  }

  return { allow: false, status: "" };
};
