import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

import { Status, UserInfo } from "types";
import { status } from "utils";
import { becomeProctor } from "./thunk";

const initialState: State = {
  user: null,
  becomeProctor: { ...status },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout: (state) => ({
      ...state,
      user: initialState.user,
    }),
  },
  extraReducers: (buildCase) =>
    buildCase
      .addCase(becomeProctor.pending, (state) => ({
        ...state,
        becomeProctor: { ...status, loading: true },
      }))
      .addCase(becomeProctor.rejected, (state) => ({
        ...state,
        becomeProctor: { ...status, failure: true },
      }))
      .addCase(becomeProctor.fulfilled, (state) => ({
        ...state,
        becomeProctor: { ...status, success: true },
      })),
});

export const selectUser = (state: RootState) => state.users.user;
export const selectBecomeProctor = (state: RootState) =>
  state.users.becomeProctor;

export const { logout } = usersSlice.actions;

type State = {
  user: UserInfo | null;
  becomeProctor: Status;
};

export default usersSlice.reducer;
