import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  finishExamAPI,
  getAllCountReportsAPI,
  getAllReportsInfoAPI,
  getCourseListAPI,
  getCourseQuizzesAPI,
  getCourseUsersAPI,
  getLmsListAPI,
  getOrganizationInfoAPI,
  getOrganizationResultsAPI,
  getProctoredQuizAPI,
  getProctoredQuizzesAPI,
  getQuizResultsAPI,
  getQuizStatsAPI,
  lmsStartExamAPI,
  postCheatingInfoAPI,
  postCheatingStatusAPI,
} from "./api";

export const getLmsList = createAsyncThunk("lms/getLmsList", async () => {
  const res = await getLmsListAPI();
  return res;
});

export const getCourseList = createAsyncThunk("lms/getCourseList", async () => {
  const res = await getCourseListAPI();
  return res;
});

export const getProctoredQuizzes = createAsyncThunk(
  "lms/getProctoredQuizzes",
  async () => {
    const res = await getProctoredQuizzesAPI();
    return res;
  }
);

export const getCourseQuizzes = createAsyncThunk(
  "lms/getCourseQuizzes",
  async (id: number) => {
    const res = await getCourseQuizzesAPI(id);
    return res;
  }
);

export const getCourseUsers = createAsyncThunk(
  "lms/getCourseUsers",
  async (id: number) => {
    const res = await getCourseUsersAPI(id);
    return res;
  }
);

export const getProctoredQuiz = createAsyncThunk(
  "lms/getProctoredQuiz",
  async (cheatingCode: string) => {
    const res = await getProctoredQuizAPI(cheatingCode);
    return res;
  }
);

export const getQuizResults = createAsyncThunk(
  "lms/getQuizResults",
  async (id: number) => {
    const res = await getQuizResultsAPI(id);
    if (res && !!res.length)
      return res.map((el) => ({
        ...el,
        firstName: el.firstName || "",
        lastName: el.lastName || "",
      }));
    return res;
  }
);

export const getQuizStats = createAsyncThunk(
  "lms/getQuizStats",
  async (id: number) => {
    const res = await getQuizStatsAPI(id);
    return res;
  }
);

export const postCheatingInfo = createAsyncThunk(
  "lms/postCheatingInfo",
  async ({
    cheatingWeight,
    cheatingCount,
    cheatingCode,
  }: {
    cheatingWeight: number;
    cheatingCount: number;
    cheatingCode: string;
  }) => {
    const res = await postCheatingInfoAPI(
      cheatingWeight,
      cheatingCount,
      cheatingCode
    );
    return res;
  }
);

export const postCheatingStatus = createAsyncThunk(
  "lms/postCheatingStatus",
  async ({
    status,
    cheatingCode,
  }: {
    status: "not_cheated" | "cheated";
    cheatingCode: string;
  }) => {
    const res = await postCheatingStatusAPI(status, cheatingCode);
    return res;
  }
);

export const lmsStartExam = createAsyncThunk(
  "lms/lmsStartExam",
  async ({
    duration,
    cheatingCode,
  }: {
    duration: number;
    cheatingCode: string;
  }) => {
    const res = await lmsStartExamAPI(cheatingCode, duration);
    return res;
  }
);

export const finishExam = createAsyncThunk(
  "lms/finishExam",
  async (cheatingCode: string) => {
    const res = await finishExamAPI(cheatingCode);
    return res;
  }
);

export const getOrganizationInfo = createAsyncThunk(
  "lms/getOrganizationInfo",
  async ({
    orgId,
    video,
    startDate,
    endDate,
  }: {
    orgId: string;
    video: boolean;
    startDate: Date;
    endDate: Date;
  }) => {
    const res = await getOrganizationInfoAPI(orgId, video, startDate, endDate);
    return res;
  }
);

export const getAllCountReports = createAsyncThunk(
  "lms/getAllCountReports",
  async (orgId: number) => {
    const res = await getAllCountReportsAPI(orgId);
    return res;
  }
);

export const getAllReportsInfo = createAsyncThunk(
  "lms/getAllReportsInfo",
  async ({ orgId, startDate }: { orgId: number; startDate: number }) => {
    const res = await getAllReportsInfoAPI(orgId, startDate);
    return res;
  }
);

export const getOrganizationResults = createAsyncThunk(
  "lms/getOrganizationResults",
  async () => {
    const res = await getOrganizationResultsAPI();
    return res;
  }
);
