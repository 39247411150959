import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  authorizeImageAPI,
  becomeProctorAPI,
  becomeSuperAdminAPI,
  getUserInfoAPI,
  makeProctorAPI,
  oauthAuthAPI,
  refreshTokenAPI,
  removeTokenAPI,
  updateImageAPI,
} from "./api";

export const oauthAuth = createAsyncThunk(
  "users/oauthAuth",
  async ({
    organizationName,
    code,
  }: {
    organizationName: string;
    code: string;
  }) => {
    const res = await oauthAuthAPI(organizationName, code);
    return res;
  }
);

export const getUserInfo = createAsyncThunk("users/getUserInfo", async () => {
  const res = await getUserInfoAPI();

  if (res) localStorage.setItem("user", JSON.stringify(res));

  return res;
});

export const makeProctor = createAsyncThunk(
  "users/makeProctor",
  async ({
    userId,
    status,
  }: {
    userId: number;
    status: "student" | "proctor";
  }) => {
    const res = await makeProctorAPI(userId, status);
    return res;
  }
);

export const updateImage = createAsyncThunk(
  "users/updateImage",
  async (data: FormData) => {
    const res = await updateImageAPI(data);
    return res;
  }
);

export const authorizeImage = createAsyncThunk(
  "users/authorizeImage",
  async ({ data, cheatingCode }: { data: FormData; cheatingCode: string }) => {
    const res = await authorizeImageAPI(data, cheatingCode);
    return res;
  }
);

export const removeToken = createAsyncThunk("users/removeToken", async () => {
  const res = await removeTokenAPI();
  return res;
});

export const refreshToken = createAsyncThunk("users/refreshToken", async () => {
  const res = await refreshTokenAPI();
  return res;
});

export const becomeProctor = createAsyncThunk(
  "users/becomeProctor",
  async (code: string) => {
    const res = await becomeProctorAPI(code);
    return res;
  }
);

export const becomeSuperAdmin = createAsyncThunk(
  "users/becomeSuperAdmin",
  async (code: string) => {
    const res = await becomeSuperAdminAPI(code);
    return res;
  }
);
