import { configureStore } from "@reduxjs/toolkit";

import commonSlice from "./common/slice";
import lmsSlice from "./lms/slice";
import reportSlice from "./report/slice";
import usersSlice from "./users/slice";

export const store = configureStore({
  reducer: {
    common: commonSlice,
    lms: lmsSlice,
    report: reportSlice,
    users: usersSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
