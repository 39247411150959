import * as React from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CameraIcon from "@material-ui/icons/Camera";
import MicIcon from "@material-ui/icons/Mic";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";

import { Layout } from "components/Layout";
import { CommonTable } from "components/Table";
import { Search } from "components/Search";

import { selectStudents } from "store/lms/slice";
import { getCourseUsers } from "store/lms/thunk";
import { selectImageRequest } from "store/common/slice";

import { getDeviceStatusColor, getDeviceStatusTooltip } from "utils";
import { UserImageRequest, UserInfo } from "types";
import { approveImageRequest, rejectImageRequest } from "store/common/thunk";

const useStyles = makeStyles(() =>
  createStyles({
    info: {
      display: "flex",
    },
    imageBox: {
      width: 40,
      height: 40,
      objectFit: "cover",
      cursor: "pointer",
      borderRadius: "50%",
    },
    defaultImg: {
      width: 40,
      height: 40,
    },
    infoTitle: {
      marginLeft: 20,
    },
    infoIcon: {
      width: 40,
      height: 40,
    },
    sameProfileImgWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: "50px 0",
    },
    sameProfileImg: {
      borderRadius: "50%",
      width: 150,
      height: 150,
      objectFit: "cover",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const { useState, useEffect } = React;

const StudentsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams<{ id?: string }>();

  const classes = useStyles();

  const cells = [
    t("studentsPage.student"),
    t("studentsPage.webcam"),
    t("studentsPage.micro"),
    t("studentsPage.display"),
    t("studentsPage.request"),
  ];

  const students = useSelector(selectStudents);
  const imageRequest = useSelector(selectImageRequest);

  const [openDialog, setOpenDialog] = useState(false);
  const [imgRequest, setImgRequest] = useState<UserImageRequest | null>(null);
  const [searchData, setSearchData] = useState("");
  const [zoomImg, setZoomImg] = useState<string | null>(null);
  const [currentStudent, setCurrentStudent] = useState<UserInfo | null>(null);

  const handleImgCheck = (el: UserInfo) => {
    setImgRequest(el.imageRequests[0]);
    setCurrentStudent(el);
    setOpenDialog(true);
  };

  const handleImageRequestResolve = (approve: boolean) => {
    if (approve && currentStudent)
      dispatch(approveImageRequest(imgRequest?.id || 0));
    else if (!approve && currentStudent)
      dispatch(rejectImageRequest(imgRequest?.id || 0));

    setOpenDialog(false);
  };

  useEffect(() => {
    if (params.id) dispatch(getCourseUsers(+params.id));
    else if (!students.data.length) dispatch(getCourseUsers(0));
  }, [students.data.length]);

  useEffect(() => {
    if (imageRequest.success) dispatch(getCourseUsers(0));
  }, [imageRequest.success]);

  return (
    <Layout title={t("sidebar.students")}>
      <h1>{t("students")}</h1>
      {students.status.loading && <h4>{t("search")}</h4>}
      {students.status.failure && <h4>{t("no_results")}</h4>}
      {students.data.length > 5 && (
        <Search searchData={searchData} setSearchData={setSearchData} />
      )}
      {!!students.data.length && (
        <CommonTable cells={cells} align="center">
          {students.data
            .filter(
              (item) =>
                item &&
                item.firstName
                  ?.toUpperCase()
                  .indexOf(searchData.toUpperCase()) > -1
            )
            .map((el) => (
              <TableRow key={el.id}>
                <TableCell component="th" scope="row">
                  <div className={classes.info}>
                    {el.image ? (
                      <img
                        className={classes.imageBox}
                        src={el.image}
                        onClick={() => setZoomImg(el.image)}
                        aria-hidden="true"
                        alt="img"
                      />
                    ) : (
                      <AccountCircleIcon className={classes.defaultImg} />
                    )}
                    <p className={classes.infoTitle}>
                      {el.firstName} {el.lastName}
                    </p>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={getDeviceStatusTooltip(
                      el.deviceStatus?.WEBCAM?.status
                    )}
                  >
                    <CameraIcon
                      fontSize="large"
                      style={{
                        color: getDeviceStatusColor(
                          el.deviceStatus?.WEBCAM?.status
                        ),
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={getDeviceStatusTooltip(
                      el.deviceStatus?.AUDIO?.status
                    )}
                  >
                    <MicIcon
                      fontSize="large"
                      style={{
                        color: getDeviceStatusColor(
                          el.deviceStatus?.AUDIO?.status
                        ),
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={getDeviceStatusTooltip(
                      el.deviceStatus?.SCREEN?.status
                    )}
                  >
                    <PersonalVideoIcon
                      fontSize="large"
                      style={{
                        color: getDeviceStatusColor(
                          el.deviceStatus?.SCREEN?.status
                        ),
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  {!!el.imageRequests.length && (
                    <Button
                      onClick={() => handleImgCheck(el)}
                      variant="contained"
                      color="primary"
                    >
                      {t("studentsPage.accept")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </CommonTable>
      )}
      {!students.data.length && !students.status.loading && t("no_results")}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          {t("studentsPage.theSame")}
        </DialogTitle>
        <DialogContent>
          <div className={classes.sameProfileImgWrapper}>
            <img
              src={imgRequest?.image}
              alt="img"
              className={classes.sameProfileImg}
            />
            <img
              src={imgRequest?.updatedImage}
              alt="img"
              className={classes.sameProfileImg}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ margin: 20 }}>
          <Button
            onClick={() => handleImageRequestResolve(true)}
            variant="contained"
            color="primary"
          >
            {t("yes")}
          </Button>
          <Button
            onClick={() => handleImageRequestResolve(false)}
            variant="contained"
            color="secondary"
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setZoomImg(null)}
        aria-labelledby="simple-dialog-title"
        open={!!zoomImg}
      >
        {zoomImg && <img src={zoomImg} alt="" />}
      </Dialog>
    </Layout>
  );
};

export { StudentsPage };
