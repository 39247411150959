import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { changeLanguage } from "i18n";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { Badge, Menu, MenuItem } from "@material-ui/core";

import GroupIcon from "@material-ui/icons/Group";
import MenuIcon from "@material-ui/icons/Menu";
import ViewStreamIcon from "@material-ui/icons/ViewStream";
import ViewListIcon from "@material-ui/icons/ViewList";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import TranslateIcon from "@material-ui/icons/Translate";
import Brightness7Icon from "@material-ui/icons/Brightness7";

import logo from "assets/images/logo.svg";
import logoLight from "assets/images/logo-light.svg";
import aktruLogo from "assets/images/russian_university.svg";

import { setTheme } from "store/common/slice";
import { getUserInfo } from "store/users/thunk";
import { removeTokenAPI } from "store/users/api";
import { logout } from "store/users/slice";
import { routes } from "utils/routes";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      "@media print": {
        display: "none",
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      "@media print": {
        display: "none",
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
      display: "flex",
      alignItems: "center",
      paddingLeft: 16,
      "@media print": {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      "@media print": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      },
    },
    profile: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",
      },
    },
  })
);

const { useState, useEffect } = React;

const Layout: React.FC<IProps> = ({ children, title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const userFromLS = localStorage.getItem("user");
  const user = userFromLS ? JSON.parse(userFromLS) : null;
  console.log(user.organizationCode);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLang = (lang: "en" | "kz" | "ru") => {
    changeLanguage(lang);
    handleClose();
  };

  const menu = [
    {
      title: t("sidebar.courses"),
      link: routes.courses.url,
      icon: <ViewStreamIcon />,
    },
    {
      title: t("sidebar.students"),
      link: routes.students.url,
      icon: <GroupIcon />,
    },
    {
      title: t("sidebar.exams"),
      link: routes.quizzes.url,
      icon: <ViewListIcon />,
    },
  ];

  const handleThemeSwitch = () => {
    const currentTheme = localStorage.getItem("theme");
    if (!currentTheme) {
      localStorage.setItem("theme", "dark");
      dispatch(setTheme("dark"));
    } else {
      localStorage.setItem("theme", currentTheme === "dark" ? "light" : "dark");
      dispatch(setTheme(currentTheme === "dark" ? "light" : "dark"));
    }
  };

  const handleItemClick = (link: string) => history.push(link);

  const handleLogout = async () => {
    try {
      await removeTokenAPI();
      dispatch(logout());
      localStorage.removeItem("user");
      history.push(routes.auth.url);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        {user.organizationCode === "russian_university" ? (
          <img src={aktruLogo} alt="AERO" width={120} />
        ) : (
          <img
            src={theme.palette.type === "dark" ? logo : logoLight}
            alt="AERO"
          />
        )}
      </div>
      <Divider />
      <List>
        {menu.map((item) => (
          <ListItem
            button
            key={item.title}
            onClick={() => handleItemClick(item.link)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
        <ListItem button key="logout" onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t("sidebar.logout")} />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  useEffect(() => {
    if (title) document.title = `Aero | ${title}`;
  }, [title]);

  // useEffect(() => {
  //   if (!userFromLS) dispatch(getUserInfo());
  // }, [userFromLS]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {user ? (
            <div className={classes.profile}>
              {user.image && <Avatar src={user.image} />}
              <Typography style={{ marginLeft: 10 }} variant="h6" noWrap>
                {user.firstName} {user.lastName}
              </Typography>
            </div>
          ) : (
            <Typography className={classes.profile} variant="h6" noWrap>
              {title}
            </Typography>
          )}
          <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge color="secondary">
              <HelpOutlineIcon />
            </Badge>
          </IconButton>
          <IconButton
            aria-label="show 4 new mails"
            color="inherit"
            onClick={handleThemeSwitch}
          >
            <Badge color="secondary">
              <Brightness7Icon />
            </Badge>
          </IconButton>
          <IconButton
            aria-label="show 4 new mails"
            color="inherit"
            onClick={handleClick}
          >
            <Badge color="secondary">
              <TranslateIcon />
            </Badge>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleLang("en")}>English</MenuItem>
            <MenuItem onClick={() => handleLang("ru")}>Русский</MenuItem>
            <MenuItem onClick={() => handleLang("kz")}>Қазақша</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

interface IProps {
  children: React.ReactNode;
  title?: string;
}

export { Layout };
