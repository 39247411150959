import * as React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { LanguageComponent } from "components/Language";

import {
  Button,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
} from "@material-ui/core";
import SchoolIcon from "@material-ui/icons/School";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import { becomeProctor, getUserInfo } from "store/users/thunk";
import { logout, selectBecomeProctor } from "store/users/slice";
import { removeTokenAPI } from "store/users/api";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    borderRadius: 5,
    border: "0.5px solid #f2f2f2",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
  },
  title: {
    textAlign: "center",
    marginBottom: 10,
    whiteSpace: "nowrap",
  },
  description: {
    textAlign: "center",
    marginBottom: 100,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "60%",
    },
  },
  formInput: {
    marginBottom: 60,
  },
  formBtn: {
    width: 150,
    marginLeft: "auto",
    marginRight: "auto",
  },
  items: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 50,
  },
  itemWrapper: {
    textAlign: "center",
  },
  iconWrapper: {
    borderRadius: "50%",
    padding: 50,
    border: "4px solid #CBCBD0",
    "&:hover": {
      cursor: "pointer",
      border: "4px solid #0976F4",
    },
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const { useState, useEffect } = React;

const MakeAdminPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const becomeProctorStatus = useSelector(selectBecomeProctor);

  const [showForm, setShowForm] = useState<boolean>(false);
  const [value, setValue] = useState("");

  const handleSelect = (type: string) => {
    if (type === "student") {
      window.location.href =
        "https://drive.google.com/drive/folders/1oJb2yyV9brgYBX5XkQqn1J4gATS27CB7?usp=sharing";
    } else {
      setShowForm(true);
    }
  };

  const handleBack = async () => {
    if (!showForm) {
      await removeTokenAPI();
      dispatch(logout());
      localStorage.removeItem("user");
      history.push("/oauth2");
    } else {
      setShowForm(false);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(becomeProctor(value));
  };

  useEffect(() => {
    (async () => {
      if (becomeProctorStatus.success) {
        await dispatch(getUserInfo());
        history.push("/");
      }
    })();
  }, [becomeProctorStatus.success]);

  return (
    <div className={classes.wrapper}>
      <CssBaseline />
      <Container maxWidth="sm">
        <div className={classes.paper}>
          <div className={classes.topBar}>
            <KeyboardBackspaceIcon
              style={{ cursor: "pointer" }}
              onClick={handleBack}
            />
            <LanguageComponent />
          </div>
          {showForm ? (
            <>
              <h1 className={classes.title}>{t("codePage.title")}</h1>
              <p className={classes.description}>{t("codePage.description")}</p>

              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  required
                  error={becomeProctorStatus.failure}
                  className={classes.formInput}
                  id={
                    becomeProctorStatus.failure
                      ? "standard-error-helper-text"
                      : "standard-required"
                  }
                  onChange={(e) => setValue(e.target.value)}
                  helperText={
                    becomeProctorStatus.failure
                      ? t("codePage.codeWrong")
                      : t("codePage.title")
                  }
                  label={t("codePage.code")}
                />
                <Button
                  className={classes.formBtn}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {t("codePage.enter")}
                </Button>
              </form>
            </>
          ) : (
            <>
              <h1 className={classes.title}>{t("rolePage.title")}</h1>

              <div className={classes.items}>
                <div
                  className={classes.itemWrapper}
                  onClick={() => handleSelect("proctor")}
                  aria-hidden="true"
                >
                  <div className={classes.iconWrapper}>
                    <PersonIcon fontSize="large" />
                  </div>
                  <h2>{t("rolePage.proctor")}</h2>
                </div>
                <div
                  className={classes.itemWrapper}
                  onClick={() => handleSelect("student")}
                  aria-hidden="true"
                >
                  <div className={classes.iconWrapper}>
                    <SchoolIcon fontSize="large" />
                  </div>
                  <h2>{t("rolePage.student")}</h2>
                </div>
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export { MakeAdminPage };
