import { axiosRequest } from "utils/axios.config";

export const IMAGE_REQUEST_API = process.env.REACT_APP_IMAGE_REQUEST_API;

export const approveImageRequestAPI = async (id: number) =>
  axiosRequest({
    baseURL: IMAGE_REQUEST_API,
    url: `/resolve/${id}`,
    method: "PUT",
    withCredentials: true,
  });

export const rejectImageRequestAPI = async (id: number) =>
  axiosRequest({
    baseURL: IMAGE_REQUEST_API,
    url: `/reject/${id}`,
    method: "PUT",
    withCredentials: true,
  });
