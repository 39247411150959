import { axiosRequest } from "utils/axios.config";
import { Attempt, Course, Quiz, ResultStats, Univer, UserInfo } from "types";

export const LMS_API = process.env.REACT_APP_LMS_MICROSERVICE_API;

export const getLmsListAPI = () => {
  return axiosRequest<{ result: Univer[]; count: number }>({
    baseURL: LMS_API,
    url: "/organizations",
    method: "GET",
    params: {
      limit: 100,
    },
  });
};

export const getCourseListAPI = () =>
  axiosRequest<{ result: Course[]; count: number }>({
    baseURL: LMS_API,
    url: "/organizations/lms/courses",
    method: "GET",
    params: { limit: 100 },
    withCredentials: true,
  });

export const getProctoredQuizzesAPI = () =>
  axiosRequest<{ quizzes: Quiz[] }>({
    baseURL: LMS_API,
    url: "/organizations/lms/active-quiz-list/",
    method: "GET",
    withCredentials: true,
  });

export const getCourseQuizzesAPI = (id: number) =>
  axiosRequest<{ quizzes: Quiz[] }>({
    baseURL: LMS_API,
    url: `/organizations/lms/quiz-info-list/${id}`,
    method: "GET",
    withCredentials: true,
  });

export const getCourseUsersAPI = (id: number) =>
  axiosRequest<UserInfo[]>({
    baseURL: LMS_API,
    url: `/organizations/lms/users/${id}`,
    method: "GET",
    params: { limit: 1000 },
    withCredentials: true,
  });

export const getProctoredQuizAPI = (cheatingCode: string) =>
  axiosRequest({
    baseURL: LMS_API,
    url: `/quiz-list/${cheatingCode}/`,
    method: "GET",
  });

export const getQuizResultsAPI = (id: number) =>
  axiosRequest<Attempt[]>({
    baseURL: LMS_API,
    url: `/organizations/lms/quiz-results/${id}`,
    method: "GET",
    params: {
      limit: 1000,
    },
    withCredentials: true,
  });

export const getQuizStatsAPI = (id: number) =>
  axiosRequest<ResultStats>({
    baseURL: LMS_API,
    url: `/organizations/lms/quiz-stats/${id}/`,
    method: "GET",
    withCredentials: true,
  });

export const postCheatingInfoAPI = (
  cheatingWeight: number,
  cheatingCount: number,
  cheatingCode: string
) =>
  axiosRequest({
    baseURL: LMS_API,
    url: `/result/${cheatingCode}/`,
    method: "POST",
    data: {
      cheatingWeight,
      cheatingCount,
    },
  });

export const postCheatingStatusAPI = (
  status: "not_cheated" | "cheated",
  cheatingCode: string
) =>
  axiosRequest({
    baseURL: LMS_API,
    url: `/result/${cheatingCode}/status/`,
    method: "POST",
    data: { status },
    withCredentials: true,
  });

export const lmsStartExamAPI = (cheatingCode: string, duration: number) =>
  axiosRequest({
    baseURL: LMS_API,
    url: `/start-exam/${cheatingCode}/`,
    method: "POST",
    data: { duration },
  });

export const finishExamAPI = (cheatingCode: string) =>
  axiosRequest({
    baseURL: LMS_API,
    url: `/finish-exam/${cheatingCode}`,
    method: "GET",
  });

export const getOrganizationInfoAPI = (
  orgId: string,
  video: boolean,
  startDate: Date,
  endDate: Date
) => {
  let url = `/organization-info/${orgId}?`;
  if (video) {
    url += "video=true";
  }
  if (startDate) {
    url += `&start_date=${startDate.getTime()}`;
  }
  if (endDate) {
    url += `&end_date=${endDate.getTime()}`;
  }

  return axiosRequest({
    baseURL: LMS_API,
    url,
    method: "GET",
  });
};

export const getAllCountReportsAPI = (orgId: number) =>
  axiosRequest({
    baseURL: LMS_API,
    url: `/attempts/count/${orgId}`,
    method: "POST",
    data: {},
    withCredentials: true,
  });

export const getAllReportsInfoAPI = (orgId: number, startDate: number) =>
  axiosRequest({
    baseURL: LMS_API,
    url: `/attempts/info/${orgId}`,
    method: "POST",
    data: { startDate },
    withCredentials: true,
  });

export const getOrganizationResultsAPI = () =>
  axiosRequest({
    baseURL: LMS_API,
    url: "/organization-results",
    method: "GET",
  });
