import * as React from "react";

import { Dialog } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TabIcon from "@material-ui/icons/Tab";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";

import { Cheating, CheatingType } from "types";

import { getCheatingTypeTitle } from "utils";

import styles from "./style.module.css";

const { useState } = React;

const Slider: React.FC<IProps> = ({ items }) => {
  const [zoomImg, setZoomImg] = useState<string | null>(null);
  const [zoomText, setZoomText] = useState<string | null>(null);

  const getImg = (item: Cheating) => {
    switch (item.cheatingType) {
      case CheatingType.screen_access_removed:
        return <HighlightOffIcon />;
      case CheatingType.microphone_access_removed:
        return <HighlightOffIcon />;
      case CheatingType.webcam_access_removed:
        return <HighlightOffIcon />;
      case CheatingType.capturing_tab:
        return <TabIcon onClick={() => setZoomText(item.content)} />;
      case CheatingType.audio:
        return <AudiotrackIcon onClick={() => setZoomText(item.content)} />;
      default:
        return (
          <img
            src={item.image}
            style={{ cursor: "pointer" }}
            alt="img"
            onClick={() => setZoomImg(item.image)}
            aria-hidden="true"
          />
        );
    }
  };

  return (
    <>
      <ul className={styles.slider}>
        {items.map((item) => (
          <li className={styles.sliderItem} key={item.id}>
            {getImg(item)}
            <p>{getCheatingTypeTitle(item.cheatingType)}</p>
          </li>
        ))}
      </ul>
      <Dialog
        onClose={() => setZoomImg(null)}
        aria-labelledby="simple-dialog-title"
        open={!!zoomImg}
      >
        {zoomImg && <img src={zoomImg} alt="img" />}
      </Dialog>
      <Dialog
        onClose={() => setZoomText(null)}
        aria-labelledby="simple-dialog-title"
        open={!!zoomText}
      >
        {zoomText && (
          <p style={{ padding: 40, fontWeight: "bold", fontSize: 20 }}>
            {zoomText}
          </p>
        )}
      </Dialog>
    </>
  );
};

interface IProps {
  items: Cheating[];
}

export { Slider };
