import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

import { Cheating, mapDataWithStatus, Video } from "types";
import { status } from "utils";

import {
  getCheatings,
  getCheatingsVideo,
  getVideoCheatings,
  getCheatingsDPVideo,
} from "./thunk";

const initialState: State = {
  cheatings: {
    data: [],
    status: { ...status },
  },
  video: {
    data: null,
    status: { ...status },
  },
  dpVideo: {
    data: null,
    status: { ...status },
  },
  videoCheatings: {
    data: [],
    status: { ...status },
  },
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (buildCase) =>
    buildCase
      .addCase(getCheatings.pending, (state) => ({
        ...state,
        cheatings: {
          ...state.cheatings,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getCheatings.rejected, (state) => ({
        ...state,
        cheatings: {
          ...state.cheatings,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getCheatings.fulfilled, (state, { payload }) => ({
        ...state,
        cheatings: {
          data: payload.result,
          status: { ...status, success: true },
        },
      }))
      .addCase(getCheatingsVideo.pending, (state) => ({
        ...state,
        video: {
          ...state.video,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getCheatingsVideo.rejected, (state) => ({
        ...state,
        video: {
          ...state.video,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getCheatingsVideo.fulfilled, (state, { payload }) => ({
        ...state,
        video: {
          data: payload,
          status: { ...status, success: true },
        },
      }))
      .addCase(getCheatingsDPVideo.pending, (state) => ({
        ...state,
        dpVideo: {
          ...state.dpVideo,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getCheatingsDPVideo.rejected, (state) => ({
        ...state,
        dpVideo: {
          ...state.dpVideo,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getCheatingsDPVideo.fulfilled, (state, { payload }) => ({
        ...state,
        dpVideo: {
          data: payload,
          status: { ...status, success: true },
        },
      }))
      .addCase(getVideoCheatings.pending, (state) => ({
        ...state,
        videoCheatings: {
          ...state.videoCheatings,
          status: { ...status, loading: true },
        },
      }))
      .addCase(getVideoCheatings.rejected, (state) => ({
        ...state,
        videoCheatings: {
          ...state.videoCheatings,
          status: { ...status, failure: true },
        },
      }))
      .addCase(getVideoCheatings.fulfilled, (state, { payload }) => ({
        ...state,
        videoCheatings: {
          data: payload.result,
          status: { ...status, success: true },
        },
      })),
});

export const selectCheatings = (state: RootState) => state.report.cheatings;
export const selectCheatingVideo = (state: RootState) => state.report.video;
export const selectDPCheatingVideo = (state: RootState) => state.report.dpVideo;
export const selectVideoCheatings = (state: RootState) =>
  state.report.videoCheatings;

type State = {
  cheatings: mapDataWithStatus<Cheating[]>;
  video: mapDataWithStatus<Video | null>;
  dpVideo: mapDataWithStatus<Video | null>;
  videoCheatings: mapDataWithStatus<Cheating[]>;
};

export default reportSlice.reducer;
