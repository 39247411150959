import { UserInfo } from "types";
import { axiosRequest } from "utils/axios.config";

export const USERS_API = process.env.REACT_APP_USERS_MICROSERVICE_API;

export const oauthAuthAPI = (organizationCode: string, oauthCode: string) =>
  axiosRequest({
    baseURL: USERS_API,
    url: `/users/lms/oauth/`,
    method: "GET",
    params: { organizationCode, oauthCode },
    withCredentials: true,
  });

export const getUserInfoAPI = () =>
  axiosRequest<UserInfo>({
    baseURL: USERS_API,
    url: "/users/user-info",
    method: "GET",
    withCredentials: true,
  });

export const makeProctorAPI = (userId: number, status: "student" | "proctor") =>
  axiosRequest({
    baseURL: USERS_API,
    url: `/assign-proctor/${userId}/`,
    method: "POST",
    data: { status },
  });

export const updateImageAPI = (data: FormData) =>
  axiosRequest({
    baseURL: USERS_API,
    url: "/update-image/",
    method: "POST",
    data,
  });

export const authorizeImageAPI = (data: FormData, cheatingCode: string) =>
  axiosRequest({
    baseURL: USERS_API,
    url: `/authorize-image/${cheatingCode}/`,
    method: "POST",
    data,
  });

export const removeTokenAPI = () =>
  axiosRequest({
    baseURL: USERS_API,
    url: "/users/logout",
    method: "GET",
    withCredentials: true,
  });

export const refreshTokenAPI = () =>
  axiosRequest({
    baseURL: USERS_API,
    url: "/users/refresh-token/",
    method: "GET",
    withCredentials: true,
  });

export const becomeProctorAPI = (code: string) =>
  axiosRequest({
    baseURL: USERS_API,
    url: `/users/make-proctor/`,
    method: "GET",
    params: { code },
    withCredentials: true,
  });

export const becomeSuperAdminAPI = (code: string) =>
  axiosRequest({
    baseURL: USERS_API,
    url: `/make-manager/${code}/`,
    method: "GET",
    withCredentials: true,
  });
