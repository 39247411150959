import { axiosRequest } from "utils/axios.config";
import { Cheating, Video } from "types";

export const FACE_REC_API = process.env.REACT_APP_FACE_REC_MICROSERVICE_API;

export const getQuickCheatingsAPI = (attempt_id: number) =>
  axiosRequest<{ result: Cheating[] }>({
    baseURL: FACE_REC_API,
    url: `/quick-cheatings/${attempt_id}`,
    method: "GET",
    withCredentials: true,
  });

export const getCheatingsAPI = async (attempt_id: string) =>
  axiosRequest<{ result: Cheating[] }>({
    baseURL: FACE_REC_API,
    url: `/cheatings/${attempt_id}`,
    method: "GET",
    withCredentials: true,
  });

export const getFullCheatingsAPI = async (attempt_id: string) =>
  axiosRequest<{ result: Cheating[] }>({
    baseURL: FACE_REC_API,
    url: `/full-cheatings/${attempt_id}`,
    method: "GET",
    withCredentials: true,
  });

export const getCheatingsVideoAPI = async (attempt_id: string) =>
  axiosRequest<Video>({
    baseURL: FACE_REC_API,
    url: `/videos/${attempt_id}`,
    method: "GET",
    withCredentials: true,
  });

export const getCheatingsDPVideoAPI = async (attempt_id: string) =>
  axiosRequest<Video[]>({
    baseURL: FACE_REC_API,
    url: `/videos/${attempt_id}/all`,
    method: "GET",
    withCredentials: true,
  });

export const getVideoCheatingsAPI = async (
  attempt_id: string,
  videoId: number
) =>
  axiosRequest<{ result: Cheating[] }>({
    baseURL: FACE_REC_API,
    url: `/cheatings/${attempt_id}`,
    method: "GET",
    params: { videoId },
    withCredentials: true,
  });
